*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Color */

:root {
  --accent: 183 74% 44%;
}
.accent {
  color: hsl(var(--accent));
}

/* Table Start */
.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fbfbfb;
}

/* Table Start */

/* Drag Custom Start */

.row-dragging {
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.row-dragging td {
  padding: 24px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* Drag Custom End */

:where(.css-dev-only-do-not-override-j46la).ant-menu-inline-collapsed {
  width: 75px;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.power-menu::-webkit-scrollbar {
  width: 5px;
}

.power-menu::-webkit-scrollbar-track {
  background: #555;
  border-radius: 4px;
}

.power-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.power-menu::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Table Scrollbar Custom Start */


.ant-table-content::-webkit-scrollbar {
  width: 5px;
}

.ant-table-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.ant-table-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.ant-table-content::-webkit-scrollbar-thumb:hover {
  background: #888;
}
/* Table Scrollbar Custom End */